.detail-column span.title {
    color: var(--secondary-color);
    font-size: 13px;
    font-family: var(--default-font-light-italic);
}
.detail-column span.data {
    color: var(--primary-text-color);
    font-size: 13px;
    font-family: var(--default-font-light-italic);
}

.suggestions-heading {
    padding: 10px !important;
    font-family: var(--default-font-semibold);
    color: var(--grey);
    font-size: 15px;
}

ul.suggestion-list{
    max-height: 150px;
    overflow-y: scroll !important;
    padding: 0 10px 10px 10px;
}

ul.suggestion-list li {
    color: var(--primary-text-color);
    cursor: pointer !important;
    border-bottom: 1px solid lightgrey;
}

ul.suggestion-list li:hover {
    color: var(--secondary-color);
}