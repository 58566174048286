.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.login-form {
  background: #fff;
  padding: 10px 3%;
  border-radius: var(--border-radius);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34%;
}

.login-form h1 {
  font-size: 30px !important;
}

.reset-password-section {
  background-color: #fff;
  padding: 15px 5%;
  border-radius: var(--border-radius);
}

.login-page-container {
  background-image: url(./../images/inphamed-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.window-height-100-per{
  height: 100vh !important;
}

.login-header{
  height: 75px !important;
}