.lead-primary-column p,
.lead-primary-contact-column p
{
    padding: 0;
    margin: 0;
    font-family: var(--default-font-light);
}

.lead-primary-column .user-name {
    font-family: var(--default-font-light);
}

.lead-primary-column .company-name {
    color: var(--secondary-color);
}

.lead-primary-column .timestamp {
    color: var(--grey);
    font-size: 10px;
    font-family: var(--default-font-light-italic);
}

.lead-list {
    font-family: var(--default-font-light) !important;
    width: 100% !important;
}

.lead-list .source,
.lead-list .milestone {
    font-family: var(--default-font-light-italic);
    font-size: 12px;
}